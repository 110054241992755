import React from "react"

export default function Contact() {
  const html = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9013.610716384264!2d13.014628869775391!3d55.61240869999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4653a3e862f7aba5%3A0x9185d56ed3e5d83a!2sJ%C3%B6rgen%20Kocksgatan%2073%2C%20211%2020%20Malm%C3%B6!5e0!3m2!1sen!2sse!4v1653924909102!5m2!1sen!2sse" width="" height="600" style="border:0; width: 100%;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`

  return (
    <div className="max-w-6xl mx-auto w-full ">
      <div id="kontakt" className="flex w-full flex-col gap-8">
        <h2 className="">Contact</h2>
        <div>
          <p>
            <a href="mailto:info@studio73a.se" className="underline">
              info@studio73a.se
            </a>
          </p>
          <p>
            Jörgen Kocksgatan 73A
            <br /> 211 20, Malmö
          </p>
        </div>
        <div className="w-full">
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
    </div>
  )
}
