import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Contact from "../components/Contact"

export default function ContactPage() {
  return (
    <Layout>
      <SEO title="Kontakt" />
      <div className="flex flex-col md:flex-row gap-16 w-full">
        <div className="w-full">
          <Contact />
        </div>
      </div>
    </Layout>
  )
}
